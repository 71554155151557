import React from 'react';
import KitchenSinkStory, { SortFunction } from 'react-data-table-component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToggleSelect, { Label } from '../ToggleSelect';
import { parseProfit } from '../../lib/analyticsCalculations';

interface Profit {
  amount: string; // Assuming currency as a string (e.g., "$20.00")
}

interface ListingData {
  name: string;
  url: string;
  orders: number;
  units: number;
  profit: Profit;
  views: number;
}

interface AnalyticsListingTableProps {
  data: ListingData[];
  listingColumns: Array<{
    name: string;
    selector: (row: ListingData) => any;
    sortable?: boolean;
    minWidth?: string;
    maxWidth?: string;
    right?: boolean;
    sortFunction?: SortFunction<ListingData>;
  }>;
  onToggleSelect: (label: Label) => void;
  isFetching: boolean;
}

const customSort = (rowA: ListingData, rowB: ListingData) => {
  return parseProfit(rowA.profit.amount) - parseProfit(rowB.profit.amount);
};

const listingColumns = [
  {
    name: 'Listing',
    selector: (row: ListingData) => row.name,
    sortable: true,
    minWidth: '200px'
  },
  {
    name: 'Products',
    selector: (row: ListingData) => row.units,
    maxWidth: '150px',
    sortable: true
  },
  {
    name: 'URL',
    selector: (row: ListingData) => row.url,
    sortable: true
  },
  {
    name: 'Profit',
    selector: (row: ListingData) => row.profit.amount,
    maxWidth: '150px',
    right: true,
    sortable: true,
    sortFunction: customSort
  },
  {
    name: 'Orders',
    selector: (row: ListingData) => row.orders,
    maxWidth: '150px',
    sortable: true
  },
  {
    name: 'Views',
    selector: (row: ListingData) => row.views,
    maxWidth: '150px',
    sortable: true
  }
];

const AnalyticsListingTable: React.FC<AnalyticsListingTableProps> = ({
  data,
  onToggleSelect,
  isFetching
}) => {
  return (
    <>
      <div className='title'>
        <h3>Performance Details</h3>
        {/* Hide Listing/Products toggle for now */}
        <ToggleSelect
          labels={
            [
              // { value: 'listings', label: 'Listings', selected: true },
              // { value: 'products', label: 'Products' }
            ]
          }
          onSelect={onToggleSelect} // This will now accept the Label type
          className='custom-toggle'
          togglePosition='left'
        />
      </div>
      <KitchenSinkStory
        fixedHeader={false}
        noHeader={true}
        responsive={true}
        subHeader={false}
        subHeaderWrap={true}
        data={data}
        columns={listingColumns}
        pagination={true}
        progressPending={isFetching}
        defaultSortFieldId={4}
        defaultSortAsc={false}
        sortIcon={<ExpandMoreIcon />}
      />
    </>
  );
};

export default AnalyticsListingTable;
