// Grid lines styles
const lineColor = '#D9D9D9';
const lineThickness = 1;

export const sharedLineStyle = {
  stroke: lineColor,
  strokeWidth: lineThickness
};

export const PRODUCT_COLOR = '#5281CC';

export const PRODUCT_TYPE_COLORS = ['#5281CC', '#FF9168', '#7F42A3'];

// Profit over time chart
export const TOTAL_PROFIT_COLOR = '#017B40';
export const UNITS_SOLD_COLOR = '#7F42A3';
export const VIEWS_COLOR = '#E56F01';

export const SALES_BY_TIME_COLOR = '#7F42A3';
