import axios from 'axios';
import { formatAxiosRequestHeaders } from 'lib/helpers';
import { COMMERCE_API_V1_PATH, GRAPHQL_API_HOST } from '../../../constants';

export const RECEIVE_ANALYTICS_OVERVIEW_DATA =
  'RECEIVE_ANALYTICS_OVERVIEW_DATA';
export const SET_DEFAULT_ANALYTICS_OVERVIEW_DATA =
  'SET_DEFAULT_ANALYTICS_OVERVIEW_DATA';
export const SET_FETCHING_ANALYTICS_OVERVIEW =
  'SET_FETCHING_ANALYTICS_OVERVIEW';

export const RECEIVE_ANALYTICS_GRAPHQL = 'RECEIVE_ANALYTICS_GRAPHQL';
export const SET_DEFAULT_ANALYTICS_GRAPHQL = 'SET_DEFAULT_ANALYTICS_GRAPHQL';
export const SET_FETCHING_ANALYTICS_GRAPHQL = 'SET_FETCHING_ANALYTICS_GRAPHQL';

/**
 * Finished analytics overview data request
 * @param  {object} data      The analytics overview data
 * @return {func}             The dispatch function
 */
export const receiveAnalyticsOverview = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_ANALYTICS_OVERVIEW_DATA,
    analyticsOverview: data,
    isFetching: false
  });
};

/**
 * Fetches analytics overview data to populate analytics section
 * @param {string} period     Time periods of either ['ACTIVE', 'ALLTIME', TODAY, 'YESTERDAY']
 * @param {string} currency   Monetary currency defaulted to 'usd'
 * @return {func}             The dispatch function
 */
export const fetchAnalyticsOverview =
  (period, currency = 'usd') =>
  async (dispatch, getState) => {
    const validPeriods = ['TODAY', 'YESTERDAY', 'ACTIVE', 'ALLTIME'];

    if (!period)
      throw new Error(
        'Missing mandatory parameter Period, for analytic overview request'
      );

    if (!validPeriods.includes(period))
      throw new Error(
        'Attempted to use an invalid period, for analytic overview request'
      );

    try {
      dispatch({
        type: SET_FETCHING_ANALYTICS_OVERVIEW,
        isFetching: true
      });

      const { user } = getState();
      const csrfToken = user.token;
      const dataUrl = `${COMMERCE_API_V1_PATH}/analytics/overview?period=${period}&currency=${currency}`;

      const { data } = await axios.get(
        dataUrl,
        formatAxiosRequestHeaders(csrfToken)
      );

      dispatch(receiveAnalyticsOverview(data));
    } catch (err) {
      dispatch({
        type: SET_DEFAULT_ANALYTICS_OVERVIEW_DATA,
        isFetching: false
      });
    }
  };

/**
 * Fetches analytics overview data to populate analytics section
 * @param {string} start_date
 * @param {string} end_date
 * @return {func}             The dispatch function
 */
export const fetchAnalyticsGraphql =
  (start_date, end_date) => async (dispatch, getState) => {
    if (!start_date)
      throw new Error(
        'Missing mandatory parameter start_date, for analytics request'
      );
    try {
      dispatch({
        type: SET_FETCHING_ANALYTICS_GRAPHQL,
        isFetching: true
      });
      const url = GRAPHQL_API_HOST;
      const { user } = getState();
      const csrfToken = user.token;
      const data = await axios.post(
        url,
        {
          query: `
            query($endDate: String!, $startDate: String!) {
              analytics(endDate: $endDate, startDate: $startDate) {
                campaign_data {
                  stats {
                    orders
                    order_units
                    profit_made {
                      amount
                    }
                    campaign_roots_data {
                      name
                      url
                      orders
                      units
                      profit {
                        amount
                      }
                      views
                    }
                    unit_sources_data {
                      source
                      totalOrders
                      totalProfit
                      totalUnits
                    }
                    order_feed {
                      order_date
                      source
                      line_items {
                        name
                        quantity
                        product_id
                        product_name
                        product_type_name
                      }
                      promo_code
                      net_profit
                      campaign_id
                      campaign_name
                    }
                    chart_data {
                      date
                      units
                      views
                      profitAsNumber
                    }
                  }
                }
              }
            }
          `,
          variables: {
            startDate: start_date,
            endDate: end_date
          }
        },
        formatAxiosRequestHeaders(csrfToken)
      );
      dispatch({
        type: RECEIVE_ANALYTICS_GRAPHQL,
        analytics: data.data,
        isFetching: false
      });
    } catch (err) {
      dispatch({
        type: SET_FETCHING_ANALYTICS_GRAPHQL,
        isFetching: false
      });
    }
  };
