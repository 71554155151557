import { useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { calculateProductCounts } from '../../lib/analyticsCalculations';
import { PRODUCT_TYPE_COLORS } from '../../constants/charts';
import PropTypes from 'prop-types';
import bag from '../../assets/bag.svg';

const ChartProductType = ({ chartData }) => {
  // count products by product types
  const productCountsArray = Object.entries(
    calculateProductCounts(chartData, 'product')
  ).map(([id, { name, count }]) => ({ id, name, count }));

  // Sort data to get top 3 product types
  const top3ProductData = productCountsArray
    .sort((a, b) => b.count - a.count)
    .slice(0, 3);
  // Find the most purchased product
  const mostPurchasedProduct = top3ProductData[0] || { type: 'None', count: 0 };

  const CustomLegend = useMemo(
    () => (props) => {
      const { payload } = props;
      return (
        <div className='customPieLegend'>
          {payload.map((entry, index) => (
            <div key={entry.dataKey || entry.value} className='legendItem'>
              <div
                style={{
                  backgroundColor:
                    PRODUCT_TYPE_COLORS[index % PRODUCT_TYPE_COLORS.length]
                }}
                className='legendColor'
              />
              <span className='truncateName'>{entry.value}</span>
            </div>
          ))}
        </div>
      );
    },
    []
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p>
            {payload[0].payload.name}: {payload[0].value}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <PieChart width={300} height={300} style={{ margin: '20px auto 20px' }}>
      <Pie
        data={top3ProductData}
        dataKey='count'
        nameKey='type'
        innerRadius={70} // Creates the doughnut effect
        outerRadius={120}
      >
        {top3ProductData.map((entry, index) => (
          <Cell
            key={`cell-${entry}`}
            fill={PRODUCT_TYPE_COLORS[index % PRODUCT_TYPE_COLORS.length]}
          />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
      <Legend
        verticalAlign='bottom'
        wrapperStyle={{ paddingTop: '20px' }}
        height={36}
        content={<CustomLegend />}
      />
      <image
        x='50%' // Center the image horizontally
        y={100} // Adjust to place above the text
        width='40'
        height='16'
        href={bag}
        transform='translate(-20, 0)' // Adjust for centering
      />
      <text
        x='50%'
        y={140}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{ fontSize: '28px', fontWeight: 'bold' }}
      >
        {mostPurchasedProduct.count}
      </text>
      <text
        x='50%'
        y={165}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{ fontSize: '12px' }}
      >
        {mostPurchasedProduct.type}
      </text>
    </PieChart>
  );
};

ChartProductType.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default ChartProductType;
